import React from "react";
import theme from "theme";
import { Theme, Button, Box, Image, LinkBox, Text, Section, Link, Strong } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				SignaturePro — 100% handcrafted signatures
			</title>
			<meta name={"description"} content={"Web site created using quarkly.io"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/65425bea85a5b2001f011cdd/images/signatureprodesign-logo2.png?v=2024-03-09T18:41:40.210Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/65425bea85a5b2001f011cdd/images/signatureprodesign-logo2.png?v=2024-03-09T18:41:40.210Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/65425bea85a5b2001f011cdd/images/signatureprodesign-logo2.png?v=2024-03-09T18:41:40.210Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/65425bea85a5b2001f011cdd/images/signatureprodesign-logo2.png?v=2024-03-09T18:41:40.210Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/65425bea85a5b2001f011cdd/images/signatureprodesign-logo2.png?v=2024-03-09T18:41:40.210Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/65425bea85a5b2001f011cdd/images/signatureprodesign-logo2.png?v=2024-03-09T18:41:40.210Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/65425bea85a5b2001f011cdd/images/signatureprodesign-logo2.png?v=2024-03-09T18:41:40.210Z"} />
			<meta name={"msapplication-TileColor"} content={"#000000"} />
		</Helmet>
		<Components.MobileSidePanelFix
			breakpoint="all"
			onloadShow={false}
			flex="0 1 auto"
			margin="0px 0px 0px 16px"
			menuPosition="right"
			width="24px"
			position="fixed"
			quarkly-title="Menu"
			top="15px"
			right="15px"
			sm-left="auto"
			sm-bottom="auto"
			z-index="15"
		>
			<Override slot="Button Text" display="none" />
			<Override
				slot="Content"
				z-index="100"
				justify-content="flex-start"
				box-shadow="0px 5px 5px 0 rgba(30, 41, 59, 0.1)"
				background="#111111"
				padding="48px 40px 38px 40px"
				width="260px"
				min-width="260px"
				max-width="260px"
			/>
			<Override slot="Button Icon" border-color="--color-header" color="white" />
			<Override
				slot="Children"
				flex-direction="column"
				display="flex"
				flex="1 1 0%"
				justify-content="space-between"
				position="relative"
			/>
			<Override slot="Children :open" display="flex" flex="1 1 0%" />
			<Override slot="Overlay" background="rgba(30, 41, 59, 0.3)" />
			<Override
				slot="Cross"
				color="#ffffff"
				width="40px"
				height="40px"
				display="flex"
				size="30px"
				top="5px"
				right="5px"
			/>
			<Override
				slot="Button"
				width="48px"
				height="48px"
				justify-content="center"
				background="rgba(17, 17, 17, 0.5)"
				border-radius="48px"
				padding="0px 0px 0px 0px"
			/>
			<Override slot="Wrapper" z-index="10" />
			<Box height="100%" display="flex" flex-direction="column" justify-content="space-between">
				<Box
					grid-template-columns="repeat(2, 1fr)"
					grid-gap="15px"
					md-align-self="flex-start"
					lg-display="flex"
					lg-flex-direction="column"
					display="flex"
					justify-content="flex-start"
					flex-direction="column"
					align-items="flex-start"
					padding="0px 0px 0px 0px"
					margin="0px 0px 0px 0px"
				>
					<Button
						href="#works"
						color="white"
						margin="0px 0px 0px 0px"
						font="--lead"
						sm-text-align="center"
						lg-font="--lead"
						text-decoration-line="initial"
						padding="0px 0px 0px 0px"
						background="transparent"
						focus-box-shadow="none"
						type="link"
					>
						Our works
					</Button>
					<Button
						href="#process"
						color="white"
						margin="0px 0px 0px 0px"
						font="--lead"
						sm-text-align="center"
						lg-font="--lead"
						text-decoration-line="initial"
						padding="0px 0px 0px 0px"
						background="transparent"
						focus-box-shadow="none"
						type="link"
					>
						Process
					</Button>
					<Button
						href="#plans"
						color="white"
						margin="0px 0px 0px 0px"
						font="--lead"
						sm-text-align="center"
						lg-font="--lead"
						text-decoration-line="initial"
						padding="0px 0px 0px 0px"
						background="transparent"
						focus-box-shadow="none"
						type="link"
					>
						Plans
					</Button>
					<Button
						href="#feedback"
						color="white"
						margin="0px 0px 0px 0px"
						font="--lead"
						sm-text-align="center"
						lg-font="--lead"
						text-decoration-line="initial"
						padding="0px 0px 0px 0px"
						background="transparent"
						focus-box-shadow="none"
						type="link"
					>
						Feedback
					</Button>
					<Button
						href="#contacts"
						color="white"
						margin="0px 0px 0px 0px"
						font="--lead"
						md-margin="0px 0px 25px 0px"
						sm-text-align="center"
						lg-font="--lead"
						text-decoration-line="initial"
						padding="0px 0px 0px 0px"
						background="transparent"
						focus-box-shadow="none"
						type="link"
					>
						Contacts
					</Button>
				</Box>
				<Box margin="0px 0px 0px 0px" bottom="0px" position="static" display="flex">
					<Box
						grid-template-columns="repeat(6, 1fr)"
						grid-gap="16px"
						display="flex"
						margin="32px 0px 0px 0px"
						padding="0px 1px 0px 0px"
					>
						<LinkBox href="https://instagram.com/signature.pro" target="_blank" lg-width="32px" lg-height="32px">
							<Image src="https://uploads.quarkly.io/65425bea85a5b2001f011cdd/images/signatureprodesign-instagram.svg?v=2023-11-01T15:06:53.858Z" display="block" />
						</LinkBox>
					</Box>
				</Box>
			</Box>
		</Components.MobileSidePanelFix>
		<Box display="flex" padding="16px 0px 0px 16px">
			<LinkBox href="https://www.producthunt.com/posts/signaturepro?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-signaturepro" target="_blank" rel="noopener">
				<Image
					src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=350868&theme=dark&period=daily"
					display="block"
					alt="SignaturePro - Create an amazing signature with professional calligraphers | Product Hunt"
					width="250px"
					height="54px"
				/>
			</LinkBox>
		</Box>
		<Section
			padding="80px 0 80px 0"
			lg-padding="60px 0 0px 0"
			md-padding="40px 0 40px 0"
			sm-padding="30px 0 30px 0"
			quarkly-title="Work in process"
			id="process"
		>
			<Override
				slot="SectionContent"
				align-items="center"
				justify-content="flex-start"
				display="flex"
				grid-gap="48px"
				sm-min-width="100%"
			/>
			<Box
				margin="0px 0px 32px 0px"
				display="flex"
				flex-direction="column"
				align-items="flex-start"
				width="100%"
			>
				<Text
					font="--headline1Xl"
					color="white"
					text-align="center"
					md-font="--headline1Md"
					margin="0px 0px 90px 0px"
					xl-font="--headline1"
					lg-font="--headline1Lg"
					lg-margin="0px 0px 70px 0px"
					md-margin="0px 0px 40px 0px"
					sm-padding="0px 0px 0px 12px"
					sm-margin="0px 0px 20px 0px"
				>
					Sitemap
				</Text>
				<Components.QuarklycommunityKitMenu
					font="--lead"
					rootId="/lp"
					filterPages="/lp/pages-sitemap"
					width="100%"
					display="grid"
					grid-template-columns="repeat(4, 1fr)"
					xl-grid-template-columns="repeat(3, 1fr)"
					lg-grid-template-columns="repeat(2, 1fr)"
					sm-grid-template-columns="repeat(1, 1fr)"
					sm-padding="0px 0px 0px 0px"
				>
					<Override
						slot="link"
						font="--lead"
						color="--lightD2"
						md-font="--base"
						sm-font="--base"
						text-decoration-line="initial"
						sm-display="flex"
						sm-width="100%"
						text-transform="capitalize"
					/>
					<Override slot="item" sm-width="100%" />
				</Components.QuarklycommunityKitMenu>
			</Box>
		</Section>
		<Section
			padding="90px 0 80px 0"
			md-padding="60px 0 30px 0"
			quarkly-title="Contacts"
			sm-padding="20px 0 30px 0"
			id="contacts"
		>
			<Override slot="SectionContent" display="flex" grid-gap="24px" />
			<Box
				width="25%"
				md-width="90%"
				sm-margin="40px 0px 0px 0"
				sm-width="100%"
				sm-padding="0px 0px 0px 0"
				lg-width="100%"
				lg-padding="0px 0 0px 0"
			>
				<Text
					margin="0px 0px 20px 0px"
					font="--headline1Xl"
					letter-spacing="1px"
					color="white"
					xl-font="--headline1"
					lg-font="--headline1Lg"
					md-font="--headline1Md"
				>
					Contacts
				</Text>
			</Box>
			<Box
				display="flex"
				margin="0px 0px 50px 0px"
				grid-template-columns="1fr auto 1fr"
				grid-gap="36px 48px"
				lg-grid-template-columns="repeat(3, 1fr)"
				lg-flex-wrap="wrap"
				flex-wrap="no-wrap"
				lg-flex-direction="row"
				lg-display="flex"
				sm-margin="0px 0px 0px 0px"
			>
				<Box
					display="flex"
					flex-direction="column"
					grid-gap="24px"
					width="33.33%"
					lg-width="100%"
					lg-align-items="flex-start"
				>
					<Box display="flex" align-items="center" justify-content="flex-start" grid-gap="16px">
						<Image
							src="https://uploads.quarkly.io/65425bea85a5b2001f011cdd/images/signatureprodesign-footer-whatsapp-logo.png?v=2023-11-06T07:41:34.125Z"
							display="block"
							width="28px"
							min-width="28px"
							srcSet="https://smartuploads.quarkly.io/65425bea85a5b2001f011cdd/images/signatureprodesign-footer-whatsapp-logo.png?v=2023-11-06T07%3A41%3A34.125Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65425bea85a5b2001f011cdd/images/signatureprodesign-footer-whatsapp-logo.png?v=2023-11-06T07%3A41%3A34.125Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65425bea85a5b2001f011cdd/images/signatureprodesign-footer-whatsapp-logo.png?v=2023-11-06T07%3A41%3A34.125Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65425bea85a5b2001f011cdd/images/signatureprodesign-footer-whatsapp-logo.png?v=2023-11-06T07%3A41%3A34.125Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65425bea85a5b2001f011cdd/images/signatureprodesign-footer-whatsapp-logo.png?v=2023-11-06T07%3A41%3A34.125Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65425bea85a5b2001f011cdd/images/signatureprodesign-footer-whatsapp-logo.png?v=2023-11-06T07%3A41%3A34.125Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65425bea85a5b2001f011cdd/images/signatureprodesign-footer-whatsapp-logo.png?v=2023-11-06T07%3A41%3A34.125Z&quality=85&w=3200 3200w"
							sizes="(max-width: 479px) 100vw,(max-width: 639px) 100vw,(max-width: 959px) 100vw,(max-width: 1199px) 100vw,100vw"
						/>
						<Link
							font="--base"
							color="white"
							margin="0px 0px 0px 0px"
							text-decoration-line="initial"
							href="https://api.whatsapp.com/send?phone=447418353247"
						>
							+447418353247
						</Link>
					</Box>
					<Box display="flex" align-items="center" justify-content="flex-start" grid-gap="16px">
						<Image
							src="https://uploads.quarkly.io/65425bea85a5b2001f011cdd/images/signatureprodesign-footer-instagram-logo.png?v=2023-11-06T07:45:31.334Z"
							display="block"
							width="28px"
							min-width="28px"
							srcSet="https://smartuploads.quarkly.io/65425bea85a5b2001f011cdd/images/signatureprodesign-footer-instagram-logo.png?v=2023-11-06T07%3A45%3A31.334Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65425bea85a5b2001f011cdd/images/signatureprodesign-footer-instagram-logo.png?v=2023-11-06T07%3A45%3A31.334Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65425bea85a5b2001f011cdd/images/signatureprodesign-footer-instagram-logo.png?v=2023-11-06T07%3A45%3A31.334Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65425bea85a5b2001f011cdd/images/signatureprodesign-footer-instagram-logo.png?v=2023-11-06T07%3A45%3A31.334Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65425bea85a5b2001f011cdd/images/signatureprodesign-footer-instagram-logo.png?v=2023-11-06T07%3A45%3A31.334Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65425bea85a5b2001f011cdd/images/signatureprodesign-footer-instagram-logo.png?v=2023-11-06T07%3A45%3A31.334Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65425bea85a5b2001f011cdd/images/signatureprodesign-footer-instagram-logo.png?v=2023-11-06T07%3A45%3A31.334Z&quality=85&w=3200 3200w"
							sizes="(max-width: 479px) 100vw,(max-width: 639px) 100vw,(max-width: 959px) 100vw,(max-width: 1199px) 100vw,100vw"
						/>
						<Link
							font="--base"
							color="white"
							margin="0px 0px 0px 0px"
							href="https://instagram.com/signature.pro"
							text-decoration-line="initial"
						>
							@signature.pro
						</Link>
					</Box>
					<Box display="flex" align-items="flex-start" justify-content="flex-start" grid-gap="16px">
						<Image
							src="https://uploads.quarkly.io/65425bea85a5b2001f011cdd/images/signatureprodesign-footer-email.png?v=2023-11-06T07:46:38.797Z"
							display="block"
							min-width="28px"
							width="28px"
							srcSet="https://smartuploads.quarkly.io/65425bea85a5b2001f011cdd/images/signatureprodesign-footer-email.png?v=2023-11-06T07%3A46%3A38.797Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65425bea85a5b2001f011cdd/images/signatureprodesign-footer-email.png?v=2023-11-06T07%3A46%3A38.797Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65425bea85a5b2001f011cdd/images/signatureprodesign-footer-email.png?v=2023-11-06T07%3A46%3A38.797Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65425bea85a5b2001f011cdd/images/signatureprodesign-footer-email.png?v=2023-11-06T07%3A46%3A38.797Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65425bea85a5b2001f011cdd/images/signatureprodesign-footer-email.png?v=2023-11-06T07%3A46%3A38.797Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65425bea85a5b2001f011cdd/images/signatureprodesign-footer-email.png?v=2023-11-06T07%3A46%3A38.797Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65425bea85a5b2001f011cdd/images/signatureprodesign-footer-email.png?v=2023-11-06T07%3A46%3A38.797Z&quality=85&w=3200 3200w"
							sizes="(max-width: 479px) 100vw,(max-width: 639px) 100vw,(max-width: 959px) 100vw,(max-width: 1199px) 100vw,100vw"
						/>
						<Link
							font="--base"
							color="white"
							margin="0px 0px 0px 0px"
							href="mailto:order@signatureprodesign.com"
							text-decoration-line="initial"
							title="order@signatureprodesign.com"
							word-break="break-all"
						>
							order@signatureprodesign.com
						</Link>
					</Box>
					<Box
						background="#262626"
						border-radius="10px"
						color="white"
						font="--lead"
						padding="14px 18px 18px 18px"
						margin="8px 0px 0px 0px"
					>
						<Text
							font="--base"
							color="white"
							background="#262626"
							border-radius="10px"
							margin="0px 0px 8px 0px"
						>
							Custom solutions for businesses:
						</Text>
						<Link
							href="mailto:order@signatureprodesign.com"
							color="white"
							text-decoration-line="initial"
							font="--base"
							overflow-wrap="normal"
							word-break="break-all"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
							display="flex"
						>
							business@signatureprodesign.com
						</Link>
					</Box>
				</Box>
				<Box margin="0px 0 0px 0px" width="33.33%" lg-width="40%" sm-width="100%">
					<Text margin="0px 0px 20px 0px" font="--lead" color="white">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							user-select="auto"
							pointer-events="auto"
						>
							Working hours
						</Strong>
					</Text>
					<Text margin="0px 0px 15px 0px" font="--base" color="white">
						Mon-Fri: 10AM - 6PM
						<br />
						Sat: 12AM - 6PM
						<br />
						Sun: closed
					</Text>
					<Image
						src="https://uploads.quarkly.io/65425bea85a5b2001f011cdd/images/signatureprodesign-cards-1.png?v=2023-11-04T06:30:56.311Z"
						display="block"
						max-width="280px"
						lg-display="none"
						srcSet="https://smartuploads.quarkly.io/65425bea85a5b2001f011cdd/images/signatureprodesign-cards-1.png?v=2023-11-04T06%3A30%3A56.311Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65425bea85a5b2001f011cdd/images/signatureprodesign-cards-1.png?v=2023-11-04T06%3A30%3A56.311Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65425bea85a5b2001f011cdd/images/signatureprodesign-cards-1.png?v=2023-11-04T06%3A30%3A56.311Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65425bea85a5b2001f011cdd/images/signatureprodesign-cards-1.png?v=2023-11-04T06%3A30%3A56.311Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65425bea85a5b2001f011cdd/images/signatureprodesign-cards-1.png?v=2023-11-04T06%3A30%3A56.311Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65425bea85a5b2001f011cdd/images/signatureprodesign-cards-1.png?v=2023-11-04T06%3A30%3A56.311Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65425bea85a5b2001f011cdd/images/signatureprodesign-cards-1.png?v=2023-11-04T06%3A30%3A56.311Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 639px) 100vw,(max-width: 959px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
				</Box>
				<Box
					margin="0px 0 0px 0"
					display="flex"
					flex-direction="column"
					align-items="flex-start"
					width="33.33%"
					lg-width="46%"
					sm-width="100%"
				>
					<Text margin="0px 0px 20px 0px" font="--base" letter-spacing="1px" color="white">
						1920 E Hallandale Beach Blvd, #612 Hallandale Beach, FL 33009, USA
					</Text>
					<Image
						src="https://uploads.quarkly.io/65425bea85a5b2001f011cdd/images/signatureprodesign-map-1.png?v=2023-11-04T06:31:07.690Z"
						display="block"
						border-radius="15px"
						width="100%"
						md-order="-1"
						md-margin="0px 0px 20px 0px"
						srcSet="https://smartuploads.quarkly.io/65425bea85a5b2001f011cdd/images/signatureprodesign-map-1.png?v=2023-11-04T06%3A31%3A07.690Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/65425bea85a5b2001f011cdd/images/signatureprodesign-map-1.png?v=2023-11-04T06%3A31%3A07.690Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/65425bea85a5b2001f011cdd/images/signatureprodesign-map-1.png?v=2023-11-04T06%3A31%3A07.690Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/65425bea85a5b2001f011cdd/images/signatureprodesign-map-1.png?v=2023-11-04T06%3A31%3A07.690Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/65425bea85a5b2001f011cdd/images/signatureprodesign-map-1.png?v=2023-11-04T06%3A31%3A07.690Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/65425bea85a5b2001f011cdd/images/signatureprodesign-map-1.png?v=2023-11-04T06%3A31%3A07.690Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/65425bea85a5b2001f011cdd/images/signatureprodesign-map-1.png?v=2023-11-04T06%3A31%3A07.690Z&quality=85&w=3200 3200w"
						sizes="(max-width: 479px) 100vw,(max-width: 639px) 100vw,(max-width: 959px) 100vw,(max-width: 1199px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				align-items="center"
				justify-content="flex-start"
				md-align-items="center"
				md-flex-direction="column"
				flex-direction="column"
				grid-gap="24px"
			>
				<Box
					align-items="center"
					margin="0px 0px 0px -"
					justify-content="center"
					display="flex"
					lg-margin="0px 0px 0px 0px"
					flex-direction="row"
					flex-wrap="wrap"
					md-margin="0px 0px 25px 0px"
					sm-flex-direction="column"
					md-order="-1"
					grid-gap="24px"
				>
					<Link
						margin="0px 0 0px 0"
						lg-margin="0px 6px 0px 0px"
						display="flex"
						href="https://signatureprodesign.com/policy"
						font="--small"
						text-decoration-line="underline"
						color="#bfbfbf"
						hover-color="--primary"
						sm-padding="0 0 0px 0"
						target="_blank"
					>
						Privacy policy
					</Link>
					<Link
						padding="0 0 0 0"
						margin="0px 0 0px 0"
						hover-color="--primary"
						lg-margin="0px 6px 0px 0px"
						href="https://signatureprodesign.com/refund_policy"
						text-decoration-line="underline"
						color="#bfbfbf"
						font="--small"
						display="flex"
						target="_blank"
					>
						Refund policy
					</Link>
					<Link
						padding="0 0 0 0"
						margin="0px 0 0px 0"
						hover-color="--primary"
						lg-margin="0px 6px 0px 0px"
						href="https://signatureprodesign.com/terms_of_service"
						text-decoration-line="underline"
						color="#bfbfbf"
						font="--small"
						display="flex"
						target="_blank"
					>
						Terms of service
					</Link>
				</Box>
				<Text
					font="--base"
					color="#bfbfbf"
					md-margin="0px 0px 0 0px"
					width="30%"
					md-width="100%"
					margin="0 0px 0 0px"
					text-align="center"
				>
					All rights reserved
				</Text>
			</Box>
		</Section>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65425bea85a5b2001f011cdb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});